/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css"
import "/src/styles/style.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "jquery/dist/jquery.min.js"

import "bootstrap/dist/js/bootstrap.min.js"

import "@fontsource/lato"
import "@fontsource/lato/400-italic.css"
import "@fontsource/lato/100.css"
import "@fontsource/lato/100-italic.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/700-italic.css"

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onRouteUpdate = () => {
  addScript("https://code.jquery.com/jquery-3.5.1.slim.min.js")
  //addScript("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js")
  addScript(
    "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
  )
  addScript(
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
  )

  var elements = {
    header: document.querySelector(".main-nav"),
    topButton: document.getElementById("back-to-top"),
    links: document.querySelectorAll("a"),
  }

  // Add Header Shadow on Scroll ~~~~~~~~~~~~~~~~~~~~

  document.addEventListener(
    "scroll",
    function () {
      if (window.pageYOffset > 1) {
        elements.header.classList.add("show-block")
      } else {
        elements.header.classList.remove("show-block")
      }
    },
    false
  )

  // Back to Top Button Toggle Show ~~~~~~~~~~~~~~~~~~~~

  document.addEventListener(
    "scroll",
    function () {
      if (window.pageYOffset > 100) {
        elements.topButton.classList.add("show-block")
      } else if (window.pageYOffset < 100) {
        elements.topButton.classList.remove("show-block")
      } else {
      }
    },
    false
  )
}
